import React from 'react';
import { Global } from '@theme';
import { Layout } from '@components';
import { SEO } from '@components';
import {
  FinalEstimateScreen
} from '@screens/Simulator';

const QuantoCustaUmApp = () => (
  <>
    <Global />
    <Layout>
      <SEO title="Quanto custa um aplicativo ?" />
      <FinalEstimateScreen/>
    </Layout>
  </>
);

export default QuantoCustaUmApp;
